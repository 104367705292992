@import '_reset';
@import '_base';
// @import '_animations';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700,900');

$mid: #cecece;
body,
html {
  .swal2-popup {
    font-family: 'Roboto', sans-serif;

    .swal2-title {
      font-size: 26px;
      color: #143c6b;
      margin-bottom: 20px;
    }
    .swal2-content {
      font-size: 16px;
      color: #143c6b;
    }
    .swal2-styled.swal2-confirm {
      background: #11abdd;
    }
  }

  @include reset();
  * {
    box-sizing: border-box;
  }

  background: #f5f5f5;
  @media print {
    background: #fff;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $mid;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $mid;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $mid;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $mid;
  }

  .print-only {
    display: none;
    @media print {
      display: inherit;
    }
  }

  .no-print {
    display: inherit;
    @media print {
      display: none;
    }
  }
}
@page {
  size: A4 portrait;
}
