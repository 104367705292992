@mixin reset($type: null) {
  @if $type == spacing {
    margin: 0;
    padding: 0;
  }
  @if $type == list {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      margin: 0;
      padding: 0;
    }
  }
  @if $type == null {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    border-collapse: collapse;
    border-spacing: 0;
    line-height: 1.2em;
    box-sizing: border-box;
  }
}

/*

To reset all spacing font sizing on element within just do this
Make sure any other classes are below
#[main wrap component id] { 
    * {
      @include reset();
    }



}
*/
